@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Oswald:wght@600&display=swap");
:root {
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;
}

@media (min-width: 600px) {
    :root {
        --rsbs-max-w: 770px;
        --rsbs-ml: auto;
        --rsbs-mr: auto;
    }
}
* {
    box-sizing: border-box;
    outline: none;
}
button:focus {
    outline: none;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;

}


.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

@media (max-width: 767px) {
    .wrapper {
        max-width: 100%;
        padding: 0;
    }
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: inherit;
}

.ptr__pull-down .lds-ellipsis {
    display: none;
}

.leaflet-container {
    height: 30vh;
    width: 100%;
}

.link {
    text-decoration: none;
}

.noScroll {
    overflow: hidden;
    max-height: 100%;
    height: 100%;
}

.allQuakesWrapper > .leaflet-container {
    height: 100vh;
}

[data-rsbs-root] {
   position: relative;
    z-index: 998;
}
